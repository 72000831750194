<template>
  <div class="view view--product">
    <div class="view-header">
      <div class="view-header__header">
        <Btn
          v-if="helperService.userHasPermission('produit_edit')
          &&  $store.state.auth.user.data.entite.id === data?.entite?.id"
          :to="{ name: 'fertilizerEdit', params: { id: id } }"
          class="action-btn"
          text="Modifier le produit"
          icon="create"
          color="primary"
        />
      </div>
      <div class="view-header__footer">
        <BtnBack/>
        <h1 class="page-title" v-html="`${data.designation}`"></h1>
      </div>
    </div>
    <div
      class="view-body"
    >

      <Section>
        <Container>

          <h2 class="section-title">Désignation</h2>

          <div class="form grid">
            <div class="tmp-col">
              <KeyValue label="Nom générique" :value="data.nom"/>
              <KeyValue
                v-if="this.helperService.userHasProfil('super-administrateur')"
                label="EUID" :value="data.euid" />
            </div>
            <div class="col--fullwidth">
              <KeyValue label="Noms commerciaux">
                <RepeatableValues
                  v-if="repeatables.noms_commerciaux && repeatables.noms_commerciaux.length"
                  :labels="['Fournisseur', 'Nom', 'Code interne']"
                  :values="repeatables?.noms_commerciaux"
                />
                <template v-else>
                  Non renseigné
                </template>
              </KeyValue>
            </div>
            <div class="tmp-col">
              <KeyValue label="Code coopérative" :value="data.code_cooperative"/>
            </div>
          </div>

        </Container>
      </Section>
      <Section class="bg-gray-lightestest section--border-top">
        <Container>

          <h2 class="section-title">Fiche d'identité</h2>

          <div class="form grid">
            <div class="tmp-col">
              <KeyValue
                label="Sous-thèmes"
                :value="data.sous_thematiques && data.sous_thematiques.length
                ? data.sous_thematiques?.map(t => t.nom)
                : 'Non renseigné'"
                type="select"
                default="Aucun"
              />
            </div>
            <div class="tmp-col">
              <KeyValue
                label="AMM"
                :value="data.numero_amm"
              />
            </div>
            <div class="tmp-col">
              <KeyValue label="Numéro d'homologation" :value="data.numero_homologation"/>
            </div>
            <div class="tmp-col">
              <KeyValue label="Famille de produits" :value="data.famille?.nom"/>
            </div>
            <div class="tmp-col">
              <KeyValue label="Formulation" :value="data.formulation?.valeur"/>
            </div>
            <div class="tmp-col">
              <KeyValue label="Norme" :value="data.norme?.valeur"/>
            </div>
            <div class="tmp-col">
              <KeyValue label="Unité" :value="data.unite?.valeur"/>
            </div>
            <div class="tmp-col">
              <KeyValue
                label="Catégorie fonctionnelle"
                :value="data.categorie_fonctionnelle?.valeur"
              />
            </div>
            <div class="tmp-col">
              <KeyValue
                label="Catégorie matières constitutives"
                :value="data.categorie_matieres_constitutives?.valeur"
              />
            </div>
            <div class="col--fullwidth">
              <KeyValue label="Composition">
                <RepeatableValues
                  v-if="repeatables.composants && repeatables.composants.length"
                  :labels="['Substance active', 'Quantité', 'Unité']"
                  :values="repeatables?.composants"
                />
                <template v-else>
                  Non renseigné
                </template>
              </KeyValue>
            </div>
            <div class="tmp-col">
              <KeyValue
                label="Utilisable en agriculture biologique"
                :value="data.agriculturebiologique" type="radio"
              />
            </div>
            <div class="tmp-col">
              <KeyValue label="Origine" :value="data.entite?.nom" />
            </div>
            <div class="tmp-col">
              <KeyValue label="Statut actif" :value="data.actif ? 'Oui' : 'Non'" type="radio"/>
            </div>
          </div>

        </Container>
      </Section>
      <Section class="section--border-top">
        <Container>

          <h2 class="section-title">Doses conseillées</h2>

          <KeyValue label="Doses conseillées">
            <RepeatableValues
              v-if="repeatables.doses && repeatables.doses.length"
              :labels="['Dose', 'Culture', 'Unité']"
              :values="repeatables?.doses"
            />
            <template v-else>
              Non renseigné
            </template>
          </KeyValue>

        </Container>
      </Section>
      <Section class="bg-gray-lightestest section--border-top">
        <Container>

          <h2 class="section-title">Pictogrammes</h2>

          <KeyValue label="Pictogrammes">
            <div
              class="grid grid--compact"
              v-if="data.pictogrammes && data.pictogrammes.length"
            >
              <SvgChip
                v-for="pictogramme in data.pictogrammes" :key="pictogramme.id"
                :image="{uid: pictogramme.uid, media: {jeton: pictogramme.media?.jeton}, nom: pictogramme.nom}"
                noDeleteButton
              />
            </div>
            <template v-else>
              Non renseigné
            </template>
          </KeyValue>

        </Container>
      </Section>
      <Section class="section--border-top">
        <Container>

          <h2 class="section-title">Remarques</h2>

          <KeyValue label="Remarques" :value="data.remarques"/>

        </Container>
      </Section>
    </div>
  </div>

</template>

<script>
import KeyValue from '@/components/form/KeyValue.vue'
import RepeatableValues from '@/components/form/RepeatableValues.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import SvgChip from '@/components/base/SvgChip.vue'
import BtnBack from '@/components/base/BtnBack.vue'

export default {
  name: 'FertilizerView',

  components: {
    BtnBack,
    KeyValue,
    RepeatableValues,
    Btn,
    Section,
    Container,
    SvgChip,
  },

  data() {
    return {
      repeatables: {},
      data: [],
      id: null,
    }
  },

  created() {
    this.id = Number(this.$route.params.id)
    this.getData()
  },

  unmounted() {
    this.data = []
    this.repeatables = {}
  },

  methods: {
    getData() {
      this.emitter.emit('open-loader')
      this.fetchService.get(`produit/${this.id}`)
        .then((response) => {
          this.data = response.data
          if (this.data.agriculturebiologique === null) {
            this.data.agriculturebiologique = 'Non renseigné'
          } else {
            this.data.agriculturebiologique = this.data.agriculturebiologique ? 'Oui' : 'Non'
          }
          this.fetchService.get(`produit/${this.id}/dose`).then((r) => {
            this.data.doses = r.data
            this.repeatables = this.productService
              .setDatas(this.data)
              .setRepeatables(this.repeatables)
              .getRepeatablesToDisplay('noms_commerciaux', 'composants', 'doses')
          })
          this.emitter.emit('close-loader')
        },
        (responseError) => {
          console.log('fetchError', responseError)
          this.emitter.emit('close-loader')
        })
    },

  },
}
</script>
